import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const RandomCardTitles = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 0,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
      <Titles />
    </div>
  );
};


const Titles = () => {
  const titles = [
    "National Park",
    "Buy an Electric Vehicle",
    "Operate a Lemonade Stand",
    "Police Officer",
    "Mechanic",
    "Win an Amature Sporting Event",
    "Sell Your Secret Recipe",
    "Cosmetic Surgery",
    "Grandiose Mansion",
    "Humble Shack",
    "Medical Doctor",
    "National Intelligence Bureau",
    "Visit the Amusement Park",
    "Houseboat",
    "Downtown Apartment",
    "#vanlife",
    "Rural Farmhouse",
    "Profesional Athlete",
    "Security Guard",
    "Restauranteur",
    "School Teacher",
    "Architect",
    "Trendy Studio Loft",
    "Rent Out Your Property",
    "Produce a Short Film",
    "International Airport",
    "International Sensation",
    "Win an Amature Sport Competition",
    "Sell Your Secret Recipe",
    "Hire a Lawyer",
    "Flight School",
    "Go to Therapy",
    "Dream Vacation",
    "Launch a Satellite",
    "Public Transit System",
    "Free-Trade Agreement",
    "Intelligence Bureau",
    "National Festival",
    "Buy an Electric Vehicle",
  ];

  const generateRandomTitle = () => {
    return titles[Math.floor(Math.random() * titles.length)];
  };

  const generateRandomPause = () => {
    const minPause = 4;
    const pauseSpan = 10;
    return Math.ceil(Math.random() * 2 * pauseSpan + minPause);
  };

  const [title, setTitle] = useState(generateRandomTitle());
  const [pause, setPause] = useState(3);

  let rand1 = Math.random();
  let rand2 = Math.random();
  let randHorizontal = rand1 > 0.7 ? "normal" : rand1 < 0.3 ? "end" : "center";
  let randVertical = rand2 > 0.7 ? "left" : rand2 < 0.3 ? "right" : "center";

  const lToRInit = {
    opacity: 0,
    x: -50,
  };

  const lToRAni = {
    opacity: [0, 1, 0.5, 0],
    x: 50,
  };

  const rToLInit = {
    opacity: 0,
    x: 50,
  };

  const rToLAni = {
    opacity: [0, 1, 0.5, 0],
    x: -50,
  };

  useEffect(() => {
    let nextTitle = generateRandomTitle();
    setPause(generateRandomPause());

    setTimeout(() => {
      setTitle(nextTitle);
    }, pause * 500);
  }, [title]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1, duration: 5 }}
      style={{
        width: "100%",
        maxWidth: "350px",
        height: "350px",
        maxHeight: "33vh",
        color: "#FFEEAE",
        textShadow:
          "1px 1px 25px #F8DE5E, 1px -1px 25px #F8DE5E, -1px 1px 25px #F8DE5E, -1px -1px 25px #F8DE5E",
        fontWeight: "bold",
        opacity: "0.6",
        display: "flex",
        alignItems: randHorizontal,
        justifyContent: randVertical,
        fontSize: (0.5+(rand1+rand2)/2)+'rem',
      }}
    >
      <motion.span
        key={title}
        initial={{ opacity: 0, y: rand1*30 }}
        animate={{ opacity: [0, 1, 0.5, 0], y: rand2*-30 }}
        transition={{ repeat: 0, duration: 3 }}
      >
        {title}
      </motion.span>
    </motion.div>
  );
};

export default RandomCardTitles;
