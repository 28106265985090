import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuid } from "uuid";

const Subscribe = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const [status, setStatus] = useState("empty");

  const database = getDatabase();

  const sendUpdatesRequest = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString();

    set(ref(database, "want-future-updates/" + uuid()), {
      email: email,
      datetime: formattedDate,
    });

    setStatus("sent");
  };

  const validateEmail = (email) =>
    email.indexOf(" ") === -1 &&
    email.indexOf("@") === email.lastIndexOf("@") &&
    email.indexOf("@") > 1 &&
    email.lastIndexOf(".") > email.indexOf("@") + 2
      ? true
      : false;

  const handleCrowdfundingFields = (val) => {
    setEmail(val);

    validateEmail(val) ? setStatus("unsent") : setStatus("empty");
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden", marginTop: "4rem" }}>
      <div
        style={{
          width: "120%",
          marginLeft: "-10%",
          background: "rgb(248, 222, 94)",
          color: "#861",
          padding: "3rem 0rem",
          fontWeight: "bold",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow:
            "inset 0 0.25rem 0.5rem rgba(255,255,255,0.5), inset 0 -0.25rem 0.5rem rgba(0,0,0,0.5)",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          {status !== "sent" && (
            <>
              <div
                style={{
                  fontFamily: "Futuristic",
                  width: "90%",
                  maxWidth: "480px",
                  padding: "2rem",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.5rem",
                    color: "#421",
                    textDecoration: "underline",
                  }}
                >
                  Get Updates
                </h3>
                <p>
                  Would you like to stay up to date on the development of
                  SOCIETY?
                </p>
                <p>
                  Send us your email address and we'll make sure you are the
                  first to know about when/where the game will be going on sale,
                  and how you can get involved. campaign.
                </p>
                <p>We really appreciate your interest!</p>
              </div>
              <div
                style={{
                  fontFamily: "Futuristic",
                  width: "100%",
                  maxWidth: "240px",
                  padding: "60px",
                }}
              >
                <span>Your Email: </span>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    borderBottom: "solid 1px #222",
                    background: "none",
                    color: "#222",
                    padding: "0.25rem",
                    fontFamily: "Futuristic",
                    fontSize: "1rem",
                    paddingBottom: "0rem",
                    minWidth: "50%",
                  }}
                  onChange={(e) => handleCrowdfundingFields(e.target.value)}
                />
                <br />
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.97 }}
                  style={{
                    background: status !== "empty" ? "white" : "#444",
                    color: status !== "empty" ? "#222" : "#888",
                    borderRadius: "1rem",
                    padding: "0.5rem",
                    cursor: "pointer",
                    display: "inline-block",
                    marginTop: "1rem",
                  }}
                  onClick={() =>
                    status === "unsent"
                      ? sendUpdatesRequest()
                      : status === "empty"
                      ? alert(
                          "Please ensure you entered the correct email address."
                        )
                      : null
                  }
                >
                  Keep me in the loop!
                </motion.div>
              </div>
            </>
          )}
          {status === "sent" && (
            <>
              <div
                style={{
                  fontFamily: "Futuristic",
                  width: "90%",
                  maxWidth: "480px",
                  padding: "2rem",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.5rem",
                    color: "#421",
                    textDecoration: "underline",
                  }}
                >
                  Get Updates
                </h3>
                <p>
                  Thanks for your interest in Society! We'll keep you posted as we progress.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
