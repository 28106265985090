import { useState } from "react";
import { motion } from 'framer-motion';
import ImageViewer from "../components/ImageViewer";

const Multimedia = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id='gallery'>
      <h2 style={{ textAlign: "center", marginTop: "0rem" }}>
        Pictures from the Game
      </h2>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <ImageViewer filename={"personal_life_fan.jpg"} />
        <ImageViewer filename={"property_with_sectors.jpg"} />
        <ImageViewer filename={"property_fan.jpg"} />
        <ImageViewer filename={"career_with_sectors.jpg"} />
        {isExpanded && (
          <>
            <ImageViewer filename={"career_fan.jpg"} />
            <ImageViewer filename={"education_fan.jpg"} />
            <ImageViewer filename={"skill_fan.jpg"} />
            <ImageViewer filename={"first_few_spaces.jpg"} />
            <ImageViewer filename={"lowest_available_level2.jpg"} />
            <ImageViewer filename={"society_fan.jpg"} />
            <ImageViewer filename={"victory_goal_fan.jpg"} />
            <ImageViewer filename={"personal_achievement_fan.jpg"} />
            <ImageViewer filename={"personal_expense_fan.jpg"} />
            <ImageViewer filename={"public_project_fan.jpg"} />
            <ImageViewer filename={"special_effects.jpg"} />
            <ImageViewer filename={"salary_space.jpg"} />
            <ImageViewer filename={"tax_brackets.jpg"} />
            <ImageViewer filename={"public_funds.jpg"} />
            <ImageViewer filename={"publicly_funded_sectors.jpg"} />
            <ImageViewer filename={"calendar.jpg"} />
            <ImageViewer filename={"legislative_motion.jpg"} />
            <ImageViewer filename={"publicly_funded_education.jpg"} />
            <ImageViewer filename={"box_front.jpg"} />
            <ImageViewer filename={"board.jpg"} />
          </>
        )}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.97 }}
            style={{
              background: "white",
              color: "#222",
              padding: "0.5rem",
              borderRadius: "1rem",
              margin: "1rem",
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            Show {isExpanded ? "Less" : "More"}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Multimedia;
