const SpecsAndStats = () => {
  const sectionDiv = {
    border: "solid 2px #222",
    borderRadius: "1rem",
    padding: "1rem",
    margin: '1rem',
    backgroundColor: 'white',
    color: '#222',
  };

  return (
    <div style={{ backgroundColor: '#383838', color: 'white',
    backgroundImage: 'url("textures/black-thread-light.png")', }}>
      <h2 style={{ textAlign: "center", textDecoration: "underline", marginTop: '0', paddingTop: '1rem', fontFamily: 'Futuristic' }}>
        Specs & Stats
      </h2>
      <div style={sectionDiv}>
        <h3>Included in the Box:</h3>
        <ul>
          <li>1 Game Board (18"x27")</li>
          <li>6 Player Pieces</li>
          <li>
            80 Unique Personal Life Cards
            <ul>
              <li>32 Careers</li>
              <li>20 Properties</li>
              <li>16 Schools</li>
              <li>12 Skills</li>
            </ul>
          </li>
          <li>
            80 Unique Society Deck Cards
            <ul>
              <li>20 Victory Goals</li>
              <li>12 Personal Achievements</li>
              <li>24 Personal Expenses</li>
              <li>24 Public Projects</li>
            </ul>
          </li>
          <li>180 Private Investment Punchout Tokens (6 x 30)</li>
          <li>60 Public Investment Punchout Tokens</li>
          <li>24 Tax Punchout Tokens of different denominations</li>
          <li>12 Debt Punchout Tokens of different denominations</li>
          <li>1 Calendar Piece</li>
          <li>A whole stack of Society Dollars</li>
        </ul>
      </div>
      <div style={sectionDiv}>
        <h3>Length of Games</h3>
        <p>
          The time it takes to complete a game depends on a number of factors,
          such as how many players are in the game, how strategically those
          players are playing, and which ruleset is being used.
        </p>
        <p>
          A typical game of four players with at least one player present who
          has played before will usually take about 3 hours using the standard
          ruleset. Generally speaking, the game will take one hour plus
          another half hour for each player in the game.
        </p>
        <p>The game can also be played with a time limit, although we recommend planning a minimum of 2 hours to play.</p>
        <p>
          Using the quickplay ruleset (players begin with several Personal Life
          Cards & win after only one victory is acheived) a two-player game can
          be finished in under an hour, and a six-player game in under 2.5 hours.
        </p>
        <p>
          Alternatively, a six-player game using the most expanded ruleset
          possible could potentially take over 10 hours to complete. (We've
          never actually tried it.)
        </p>
        <p>
          The cooperative game mode (Develop a Socialist Utopia) generally takes
          about 2 hours to complete.
        </p>
      </div>
      <div style={sectionDiv}>
        <h3>Game Balance</h3>
        <p>
          SOCIETY is currently in it's 4th prototype iteration. Much has been
          added to or removed from previous versions, but most importantly the
          game has been finely balanced to ensure that every single Economic
          Sector presents a statistically equal chance of producing as many net
          positive or negative impacts on a given player, making it very
          difficult establish a strategy that will work every time you play the
          game.
        </p>
      </div>
      <div style={sectionDiv}>
        <h3>Learing Curve</h3>
        <p>
          While many players take their first look at the SOCIETY gameboard and feel intimidated,
          rest assured that this game can be played by anyone 12+. 
          <br/>It takes less than 10 turns for any player (gaming vet or total n00b) to have the game figured out.
        </p>
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default SpecsAndStats;
