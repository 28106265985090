import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuid } from "uuid";
import { motion } from "framer-motion";

import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [location, setLocation] = useState("Unknown Location");

  const [status, setStatus] = useState("empty");

  /*if ("geolocation" in navigator) {
    // Geolocation is available
    navigator.geolocation.getCurrentPosition(function(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
  
      // You can use latitude and longitude to get the user's location
      setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`)
    }, function(error) {
      setLocation('Location data error.')
    });
  } else {
    setLocation('Device does not support location data.')
  }*/

  const database = getDatabase();

  const validateEmail = () =>
    email && email.indexOf(" ") === -1 &&
    email.indexOf("@") === email.lastIndexOf("@") &&
    email.indexOf("@") > 1 &&
    email.lastIndexOf(".") > email.indexOf("@") + 2
      ? true
      : false;

  const handleInputFields = (val, target) => {
    target === "name" && setName(val);
    target === "email" && setEmail(val);
    target === "message" && setMessage(val);

    name && message && validateEmail()
      ? setStatus("unsent")
      : setStatus("empty");
  };

  const sendMessage = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString();

    set(ref(database, "messages/" + uuid()), {
      name: name,
      email: email,
      message: message,
      datetime: formattedDate,
      location: location,
    });

    setStatus("sent");
  };

  return (
    <div
      id="contact"
      style={{
        width: "90%",
        maxWidth: "640px",
        margin: "0 auto",
        paddingTop: "5rem",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Contact</h2>
      {status !== "sent" && (
        <>
          <p style={{ textAlign: "center" }}>
            Get in touch with us here. <br />
            We can't wait to hear from you!
          </p>

          <div style={{ marginBottom: "0.5rem" }}>
            <span>Your Name: </span>
            <input
              style={{
                border: "none",
                outline: "none",
                borderBottom: "solid 1px white",
                background: "none",
                color: "#eee",
                padding: "0.25rem",
                fontFamily: "Sleek",
                fontSize: "1rem",
                paddingBottom: "0rem",
                minWidth: "50%",
              }}
              onChange={(e) => handleInputFields(e.target.value, "name")}
            ></input>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <span>Your Email: </span>
            <input
              style={{
                border: "none",
                outline: "none",
                borderBottom: "solid 1px white",
                background: "none",
                color: "#eee",
                padding: "0.25rem",
                fontFamily: "Sleek",
                fontSize: "1rem",
                paddingBottom: "0rem",
                minWidth: "50%",
              }}
              onChange={(e) => handleInputFields(e.target.value, "email")}
            ></input>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <textarea
              style={{
                border: "1px solid #777",
                background: "#fff",
                color: "#222",
                width: "100%",
                minHeight: "100px",
                padding: "0.5rem",
                fontFamily: "Sleek",
                fontSize: "1rem",
              }}
              onChange={(e) => handleInputFields(e.target.value, "message")}
            ></textarea>
          </div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.97 }}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button
              style={{
                cursor: "pointer",
                background: status !== "empty" ? "white" : "#444",
                color: status !== "empty" ? "#222" : "#888",
                border: "none",
                borderRadius: "1rem",
                padding: "0.5rem",
                fontWeight: "bold",
                fontFamily: "Sleek",
                fontSize: "1rem",
              }}
              onClick={() => sendMessage()}
            >
              Send Email
            </button>
          </motion.div>
        </>
      )}
      {status === "sent" && (
        <>
          <p style={{ textAlign: 'center', }}>
            Thanks for your message! We'll be in touch shortly.
          </p>
        </>
      )}
    </div>
  );
};

export default Contact;
