import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuid } from "uuid";

import Contact from "../sections/Contact";

const EmailRequest = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const [selected, setSelected] = useState(props.type);

  const [status, setStatus] = useState("empty");

  const database = getDatabase();

  const sendCrowdfundingRequest = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString();

    set(ref(database, "interested-in-crowdfunding/" + uuid()), {
      email: email,
      datetime: formattedDate,
    });

    setStatus("sent");
  };

  const sendUpdatesRequest = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString();

    set(ref(database, "want-future-updates/" + uuid()), {
      email: email,
      datetime: formattedDate,
    });

    setStatus("sent");
  };

  const sendPlayRequest = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString();

    set(ref(database, "play-requests/" + uuid()), {
      name: name,
      email: email,
      location: location,
      datetime: formattedDate,
    });

    setStatus("sent");
  };

  const validateEmail = () =>
    email && email.indexOf(" ") === -1 &&
    email.indexOf("@") === email.lastIndexOf("@") &&
    email.indexOf("@") > 1 &&
    email.lastIndexOf(".") > email.indexOf("@") + 2
      ? true
      : false;

  const handleCrowdfundingFields = (val) => {
    setEmail(val);

    validateEmail() ? setStatus("unsent") : setStatus("empty");
  };

  const handleUpdateFields = (val) => {
    setEmail(val);

    validateEmail() ? setStatus("unsent") : setStatus("empty");
  };

  const handlePlayRequestFields = (val, target) => {
    target === "name" && setName(val);
    target === "email" && setEmail(val);
    target === "location" && setLocation(val);

    name && location && validateEmail()
      ? setStatus("unsent")
      : setStatus("empty");
  };

  const resetAllFields = () => {
    setName(null);
    setLocation(null);
    setEmail(null);
    setStatus("empty");

    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        style={{ color: "#0BF", display: "inline", cursor: "pointer" }}
      >
        {props.children}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0,
              background: "rgba(0,0,0,0.9)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "100",
            }}
          >
            <div style={{ color: "white", width: "100%", maxWidth: "640px" }}>
              {selected === "crowdfunding" && (
                <div>
                  <h2>Crowdfunding</h2>
                  {status !== "sent" && (
                    <>
                      <p>
                        Thank you for your interest in helping us develop our
                        game. We appreciate all help small and large.
                      </p>
                      <p>
                        We are aiming to begin our crowdfunding campaign within
                        the next 3-6 months, once all final adjustments have
                        been made and manufacturing details have been
                        established.
                      </p>
                      <p>
                        We're so happy that you want to be a part of our game!
                      </p>
                      <span>Your Email: </span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "solid 1px white",
                          background: "none",
                          color: "#eee",
                          padding: "0.25rem",
                          fontFamily: "Sleek",
                          fontSize: "1rem",
                          paddingBottom: "0rem",
                          minWidth: "50%",
                        }}
                        onChange={(e) =>
                          handleCrowdfundingFields(e.target.value)
                        }
                      />
                      <br />
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.97 }}
                        style={{
                          background: status !== "empty" ? "white" : "#444",
                          color: status !== "empty" ? "#222" : "#888",
                          borderRadius: "1rem",
                          padding: "0.5rem",
                          cursor: "pointer",
                          display: "inline-block",
                          marginTop: "1rem",
                        }}
                        onClick={() =>
                          status === "unsent"
                            ? sendCrowdfundingRequest()
                            : status === "empty"
                            ? alert(
                                "Please ensure you entered the correct email address."
                              )
                            : null
                        }
                      >
                        Keep me in the loop!
                      </motion.div>
                    </>
                  )}
                  {status === "sent" && (
                    <>
                      <p style={{ }}>
                        Thank you so much for your interest in supporting our
                        game. We'll be reaching out in the near future to let
                        people know when our crowdfunding campaign will be
                        starting.
                        <br />
                        <br />
                        If you have any questions, comments or suggestions
                        relating to our crowdfunding campaign, please contact us
                        anytime!
                      </p>
                    </>
                  )}
                </div>
              )}
              {selected === "playRequest" && (
                <div>
                  <h2>Request a Game Night</h2>
                  {status !== "sent" && (
                    <>
                      <p>
                        If you're interested in participating in our final
                        trials while the game is still in beta, we'd love to
                        have your input!
                      </p>
                      <p>
                        We'll be in Southern Ontario, Canada for the remainder
                        of 2023 hosting game nights at local boardgame cafes and
                        private events.
                      </p>
                      <p>Hope to see you soon!</p>
                      <span>Where are you?: </span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "solid 1px white",
                          background: "none",
                          color: "#eee",
                          padding: "0.25rem",
                          fontFamily: "Sleek",
                          fontSize: "1rem",
                          paddingBottom: "0rem",
                          minWidth: "50%",
                        }}
                        onChange={(e) =>
                          handlePlayRequestFields(e.target.value, "location")
                        }
                      />
                      <br/>
                      <span>Who are you?: </span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "solid 1px white",
                          background: "none",
                          color: "#eee",
                          padding: "0.25rem",
                          fontFamily: "Sleek",
                          fontSize: "1rem",
                          paddingBottom: "0rem",
                          minWidth: "50%",
                        }}
                        onChange={(e) =>
                          handlePlayRequestFields(e.target.value, "name")
                        }
                      />
                      <br/>
                      <span>What's your Email?: </span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "solid 1px white",
                          background: "none",
                          color: "#eee",
                          padding: "0.25rem",
                          fontFamily: "Sleek",
                          fontSize: "1rem",
                          paddingBottom: "0rem",
                          minWidth: "50%",
                        }}
                        onChange={(e) =>
                          handlePlayRequestFields(e.target.value, "email")
                        }
                      />
                      <br />
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.97 }}
                        style={{
                          background: status !== "empty" ? "white" : "#444",
                          color: status !== "empty" ? "#222" : "#888",
                          borderRadius: "1rem",
                          padding: "0.5rem",
                          cursor: "pointer",
                          display: "inline-block",
                          marginTop: "1rem",
                        }}
                        onClick={() =>
                          status === "unsent"
                            ? sendPlayRequest()
                            : status === "empty"
                            ? alert(
                                "Please ensure you entered your information correctly, including the correct email address."
                              )
                            : null
                        }
                      >
                        I want to play!
                      </motion.div>
                    </>
                  )}
                  {status === "sent" && (
                    <>
                      <p style={{ }}>
                        Thanks for your interest in playing our game! We will do
                        our best to make it happen, and will be in touch with
                        you either way to let you know.
                      </p>
                    </>
                  )}
                </div>
              )}
              {selected === "updates" && (
                <div>
                  <h2>Receive Updates</h2>
                  {status !== "sent" && (
                    <>
                      <p>
                        Give us your email addres if you would like to receive
                        email updates about the game, including when and where
                        it will be available for sale, future projects, and
                        chances to get involved yourself.
                      </p>
                      <p>Thanks for your interest and support!</p>
                      <span>Your Email: </span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "solid 1px white",
                          background: "none",
                          color: "#eee",
                          padding: "0.25rem",
                          fontFamily: "Sleek",
                          fontSize: "1rem",
                          paddingBottom: "0rem",
                          minWidth: "50%",
                        }}
                        onChange={(e) => handleUpdateFields(e.target.value)}
                      />
                      <br />
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.97 }}
                        style={{
                          background: status !== "empty" ? "white" : "#444",
                          color: status !== "empty" ? "#222" : "#888",
                          borderRadius: "1rem",
                          padding: "0.5rem",
                          cursor: "pointer",
                          display: "inline-block",
                          marginTop: "1rem",
                        }}
                        onClick={() =>
                          status === "unsent"
                            ? sendUpdatesRequest()
                            : status === "empty"
                            ? alert(
                                "Please ensure you entered the correct email address."
                              )
                            : null
                        }
                      >
                        Keep me in the loop!
                      </motion.div>
                    </>
                  )}
                  {status === "sent" && (
                    <>
                      <p style={{ }}>
                        Thank you for your interest in our game!
                        <br />
                        <br />
                        We'll keep the emails to a minimum.
                      </p>
                    </>
                  )}
                </div>
              )}
              {selected === "contact" && <Contact />}
            </div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1 }}
              style={{
                position: "absolute",
                top: "1rem",
                right: "4rem",
                color: "white",
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={() => resetAllFields()}
            >
              x
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default EmailRequest;
