import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const ImageViewer = ({ id, filename, size }) => {
  const [isOpen, setIsOpen] = useState(false);

  const imageList = [
    {
      id: "0001",
      title: "",
      filename: "personal_life_fan.jpg",
      description: "Cards from the Personal Life Deck",
    },

    {
      id: "0002",
      title: "",
      filename: "property_with_sectors.jpg",
      description: "A Property Card next to Affected Sectors on the gameboard.",
    },

    {
      id: "0003",
      title: "",
      filename: "property_fan.jpg",
      description: "Some examples of Property Cards.",
    },

    {
      id: "0004",
      title: "",
      filename: "career_with_sectors.jpg",
      description: "A Career Card next to its corrosponding Economic Sectors.",
    },

    {
      id: "0005",
      title: "",
      filename: "career_fan.jpg",
      description: "Some examples of Career Cards.",
    },

    {
      id: "0006",
      title: "",
      filename: "education_fan.jpg",
      description: "Some examples of Education Cards.",
    },

    {
      id: "0007",
      title: "",
      filename: "skill_fan.jpg",
      description: "Some examples of Skill Cards.",
    },

    {
      id: "0008",
      title: "",
      filename: "first_few_spaces.jpg",
      description: "The first side of the gameboard.",
    },

    {
      id: "0009",
      title: "",
      filename: "lowest_available_level2.jpg",
      description:
        "In this picture, the lowest available level for the Food Production Sector is 2, for the Manufacturing Sector is 1, and for Infrastructure is 4. New investments must always be placed in the lowest available Investment Level space, and cost the dollar amount denoted below the investment space.",
    },

    {
      id: "0010",
      title: "",
      filename: "society_fan.jpg",
      description: "The Society Deck with examples of the four types of cards.",
    },

    {
      id: "0011",
      title: "",
      filename: "victory_goal_fan.jpg",
      description: "Some examples of Victory Goal cards.",
    },

    {
      id: "0012",
      title: "",
      filename: "personal_achievement_fan.jpg",
      description: "Some examples of Personal Acheviement cards.",
    },

    {
      id: "0013",
      title: "",
      filename: "personal_expense_fan.jpg",
      description: "Some examples of Personal Expense cards.",
    },

    {
      id: "0014",
      title: "",
      filename: "public_project_fan.jpg",
      description: "Some examples of Public Project cards.",
    },

    {
      id: "0015",
      title: "",
      filename: "special_effects.jpg",
      description:
        "Some examples of Public Project and Personal Expense cards with special effects.",
    },
    {
      id: "0016",
      title: "",
      filename: "salary_space.jpg",
      description: "The Salary Space.",
    },

    {
      id: "0017",
      title: "",
      filename: "tax_brackets.jpg",
      description:
        "The Tax Brackets area with Tax Tokens on it. The taxes paid in a given bracket is equal to the cumulative value of any tokens in that bracket or lower. Here, the Base rate is $25 for any income, anyone making >$150 pays $50 ($25 extra), and anyone making >$250 pays $100 ($50 on top of the previous two brackets) in taxes.",
    },

    {
      id: "0018",
      title: "",
      filename: "public_funds.jpg",
      description: "The Public Funds space on the gameboard.",
    },

    {
      id: "0019",
      title: "",
      filename: "publicly_funded_sectors.jpg",
      description: "Public Investment Tokens on the Society gameboard.",
    },
    {
      id: "0020",
      title: "",
      filename: "calendar.jpg",
      description: "The Calendar Seasons",
    },

    {
      id: "0021",
      title: "",
      filename: "legislative_motion.jpg",
      description: "The Legislative Motion space.",
    },

    {
      id: "0022",
      title: "",
      filename: "publicly_funded_education.jpg",
      description:
        "A Publiclly Funded Education Sector with THREE Public Investments.",
    },

    {
      id: "0023",
      title: "",
      filename: "box_front.jpg",
      description:
        "The Front of the Box for the current version. All of the art will be improved for the final retail version.",
    },
    {
      id: "0024",
      title: "",
      filename: "board.jpg",
      description: "The Society Game Board",
    },
  ];

  let style =
    size === "large"
      ? {
          width: "100%",
        }
      : size === "thumbnail"
      ? {
          width: "80px",
        }
      : {
          width: "300px",
          margin: "1rem",
          cursor: "pointer",
          textAlign: "center",
        };

  const getIdFromFilename = (filename) => {
    return imageList.find(
      (item) =>
        Object.keys(item).includes("filename") && item["filename"] === filename
    ).id;
  };

  const [currentId, setCurrentId] = useState(
    id ? id : filename ? getIdFromFilename(filename) : "0001"
  );
  const [currentObj, setCurrentObj] = useState({
    id: "0001",
    title: "",
    filename: "personal_life_fan.jpg",
    description: "Cards from the Personal Life Deck",
  });

  const getObj = (id) => {
    return imageList.find(
      (item) => Object.keys(item).includes("id") && item["id"] === id
    );
  };

  useEffect(() => {
    const currentObj = imageList.find(
      (item) => Object.keys(item).includes("id") && item["id"] === currentId
    );
  
    setCurrentObj(currentObj);
  }, [currentId]);
  

  const btnStyle = {
    fontSize: "2rem",
    background: "#222",
    color: "white",
    padding: "0.5rem",
    margin: "0.5rem",
    borderRadius: "1rem",
    width: "1rem",
    height: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
  };

  return (
    <>
      <motion.div style={style} onClick={() => setIsOpen(true)} whileHover={{ scale: 1.05 }} whileTap={{ opacity: 0 }}>
        <img
          src={"/img/gallery/" + currentObj.filename}
          alt={currentObj.description}
          style={{
            border: "solid 0.35rem #222",
            marginBottom: "-0.25rem",
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto",
            objectFit: "contain",
          }}
        />
        <span style={{ fontSize: "0.8rem" }}>Click to Enlarge</span>
      </motion.div>
      <AnimatePresence>
      {isOpen && (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0.9)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <img
            src={"/img/gallery/" + currentObj.filename}
            alt={currentObj.description}
            style={{ maxWidth: "100%", maxHeight: "80%", marginTop: "1rem" }}
          />
          <div
            style={{
              color: "#222",
              fontFamily: "Sleek",
              fontWeight: "normal",
              background: "white",
              borderRadius: "2rem",
              marginTop: "1rem",
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={btnStyle}
              onClick={() =>
                Number(currentId) > 1.9 && setCurrentId(String(Number(currentId) - 1).padStart(4, "0"))
              }
            >
              &lt;
            </div>
            <span>{currentObj.description}</span>
            <div
              style={btnStyle}
              onClick={() =>
                Number(currentId) < imageList.length-1 && setCurrentId(String(Number(currentId) + 1).padStart(4, "0"))
              }
            >
              &gt;
            </div>
          </div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1 }}
            style={{
              position: "absolute",
              top: "1rem",
              right: "4rem",
              color: "white",
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            x
          </motion.div>
        </motion.div>
      )}</AnimatePresence>
    </>
  );
};

export default ImageViewer;
