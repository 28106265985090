// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHvVTgvD0loyOFmYcj4PZXbAdtHVm2ssI",
  authDomain: "build-your-society-173489.firebaseapp.com",
  databaseURL: "https://build-your-society-173489-default-rtdb.firebaseio.com",
  projectId: "build-your-society-173489",
  storageBucket: "build-your-society-173489.appspot.com",
  messagingSenderId: "309210768121",
  appId: "1:309210768121:web:21713a54b9ef7691eeb828",
  measurementId: "G-3VRVMF9VTC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);