import { BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';

const Footer = () => {
  const socialLink = {
    color: "#EEE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "16px",
    fontSize: '1.7rem',
  };

  const area = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1rem",
    marginRight: "1rem",
    color: "white",
  };

  return (
    <div
      style={{
        width: "100%",
        height: "64px",
        background: "#484848",
        color: "#000",
        marginTop: "2rem",
        borderTop: "#888 solid 4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={area}>
        <span style={{ fontSize: "16px" }}>©Society 2023 | Patent Pending</span>
      </div>
      <div style={area}>
          <a href="https://www.facebook.com/profile.php?id=61550115103671"><span style={socialLink}><BsFacebook/></span></a>
          <a href="https://www.instagram.com/societytheboardgame/"><span style={socialLink}><BsInstagram/></span></a>
          <a href="https://twitter.com/societyisagame"><span style={socialLink}><BsTwitter/></span></a>
      </div>
    </div>
  );
};

export default Footer;
