import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Nav = ({ scrollY, setSelectedTab }) => {
  const menuItem = {
    width: "100%",
    padding: "0.5rem",
    color: "#222",
    textAlign: "center",
  };

  const exitBtn = {
    width: "100%",
    padding: "0.5rem",
    color: "#B33",
    textAlign: "center",
    marginTop: "1rem",
  };

  const link = {
    color: "inherit",
    textDecoration: "none",
  };

  const [isOpen, setIsOpen] = useState(false);

  const goToGameplayTab = (tab) => {
    setSelectedTab(tab);
    setIsOpen(false);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          padding: "2rem",
          zIndex: "99",
        }}
      >
        <motion.div
          initial={{ y: -200, opacity: 0.8 }}
          animate={{ y: 0 }}
          transition={{ duration: 1, delay: 0.1, type: 'spring', bounce: 0.7 }}
          whileHover={{ scale: 1.2, opacity: 1 }}
          whileTap={{ scale: 1 }}
          style={{
            cursor: "pointer",
            background: '#FFF',
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            color: "#222",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: '12px',
            fontWeight: 'bold',
            rotate: '30deg',
            border: 'solid 2px #222',
            fontFamily: 'Futuristic'
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          Menu
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 100,
              background: "rgba(0,0,0,0.7)",
            }}
          >
            <div
              style={{
                maxWidth: "30rem",
                maxHeight: "80rem",
                background: "white",
                border: "solid 0.75rem #222",
                borderRadius: "2rem",
                padding: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#222",
                  textDecoration: "underline",
                  marginBottom: "2rem",
                  fontFamily: 'Modern',
                }}
              >
                Main Menu
              </div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => goToGameplayTab("1")}
              >
                <a
                  style={link}
                  href="#gameplay"
                >
                  Game Summary
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => goToGameplayTab("2")}
              >
                <a
                  style={link}
                  href="#gameplay"
                >
                  Specs & Stats
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => goToGameplayTab("3")}
              >
                <a
                  style={link}
                  href="#gameplay"
                >
                  How to Play
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => goToGameplayTab("4")}
              >
                <a
                  style={link}
                  href="#gameplay"
                >
                  Current Status
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => setIsOpen(false)}
              >
                <a
                  style={link}
                  href="#gallery"
                >
                  Gallery
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={menuItem}
                  onClick={() => setIsOpen(false)}
              >
                <a
                  style={link}
                  href="#contact"
                >
                  Contact
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={exitBtn}
                  onClick={() => setIsOpen(false)}
              >
                <div
                  style={{
                    color: "#B33",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: 'bold',
                  }}
                >
                  Exit Menu
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Nav;
