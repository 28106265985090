import EmailRequest from '../components/EmailRequest';

const CurrentGameStatus = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: "0.5rem 1rem",
        backgroundColor: "#444",
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: 'url("textures/random-grey-variations.png")',
      }}
    >
      <div
        style={{
          padding: "0.05rem 2rem 1rem 2rem",
          maxWidth: "600px",
          margin: "0 auto",
          background: "white",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            textDecoration: "underline",
            fontFamily: "Futuristic",
          }}
        >
          Current Game Status
        </h2>
        <p>
          The game is currently in a final prototyping stage, with the bulk of
          the gameplay and balance established. We are still working on
          finalizing a few details as we gain feedback from trials with new
          players.
        </p>
        <p>
          Throughout Q3 & Q4 of 2023 we will be touring Ontario (Canada), offering game trials at
          boardgame cafes and gameshops accross the province - and we are looking for all the feedback we can get. If there is a gameshop or cafe you'd like to play SOCIETY at, please <EmailRequest type='playRequest'>let us know!</EmailRequest>
        </p>
        <p>
          By the end of Q1 2024 we aim to have a polished final product, with optimized card and board layouts, and firm pricing quotes for manufacturing runs of different scales. In Q2 2024 we will begin a crowdfunding round so that we can begin manufacturing and shipping in Q3.
        </p>
        <p>If you would like to be a part of our crowdfunding round, then please <EmailRequest type='contact'>email us</EmailRequest> or <EmailRequest type='crowdfunding'>click here</EmailRequest> to request updates relating to future crowdfunding. We will be offering special incentives to retailers who may be interested in bulk-order funding.</p>
      </div>
    </div>
  );
};

export default CurrentGameStatus;
