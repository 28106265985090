import { useState } from "react";
import { motion } from "framer-motion";

const Header = ({ scrollY }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            maxHeight: "120vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <motion.div
            initial={{
              opacity: 1,
            }}
            animate={{
              opacity: scrollY > window.innerHeight * 0.8 ? 0 : 1,
            }}
            style={{
              marginTop: scrollY / 1.7,
              background: "#222",
              backgroundImage: "url('/img/uniformpeople.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "left",
              width: "100vw",
              maxWidth: "120vh",
              height: "100%",
              zIndex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              boxShadow: "inset 0rem 0 0.2rem #222, 0 0 10rem 0rem #F8DE8E",
            }}
          >
            <motion.div
              style={{
                position: "fixed",
                top: 0,
                margin: "0 auto",
                width: "100%",
                maxWidth: "120vh",
                height: "100%",
                background: "url('/img/victoriousperson.png') center center",
                opacity: scrollY / (window.innerHeight/2),
                backgroundSize: "cover",
                boxShadow: "inset 0rem 0 0.2rem #222",
              }}
            />
            <span>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </span>
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <motion.img
                initial={{
                  y: 0,
                }}
                animate={{
                  y: scrollY / 2.5,
                }}
                transition={{
                  velocity: "fast",
                  spring: "0",
                  stiffness: "1",
                }}
                src="/img/societylogo.png"
                alt="Society Boardgame Logo"
                style={{
                  width: "75vw",
                  maxWidth: "100vh",
                  position: "absolute",
                }}
              />
            </div>
            <motion.span
              initial={{ opacity: "1" }}
              animate={{ opacity: (300 - scrollY) / 300 }}
              style={{
                color: "#F8DE5E",
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Modern",
                textShadow:
                  "1px 1px #222, 1px -1px #222, -1px 1px #222, -1px -1px #222",
              }}
            >
              The Epic Battle of Investments, Politics, & Personal Greatness
            </motion.span>
            <span>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </span>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Header;
