import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

import AboutGame from "./AboutGame";
import HowToPlay from "./HowToPlay";
import CurrentGameStatus from "./CurrentGameStatus";
import SpecsAndStats from "./SpecsAndStats";

const Gameplay = ({ scrollY, selectedTab, setSelectedTab }) => {
  const gameplayButtons = {
    border: "solid 2px #222",
    borderRadius: "8px",
    padding: "4px",
    backgroundColor: "#FFF",
    color: "#222",
    cursor: "pointer",
  };

  const gameplayButtonActive = {
    border: "solid 2px #222",
    borderRadius: "8px",
    padding: "4px",
    backgroundColor: "#F8DE5E",
    color: "#222",
    cursor: "none",
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to the top when the component mounts or content changes
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [selectedTab]);

  return (
    <div
      id="gameplay"
      style={{
        height: "87vh",
        maxWidth: "1000px",
        background: "white",
        backgroundImage: 'url("textures/hexellence.png")',
        border: "solid 10px #F8DE5E",
        borderRadius: "1rem",
        color: "#282828",
        padding: "0rem 0.5rem",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "inset 0 0.25rem 0.5rem rgba(0,0,0,0.5), inset 0 -0.25rem 0.5rem rgba(0,0,0,0.5), 0 -0.25rem 2rem rgba(246,200,100,0.5), 0 0.25rem 2rem (246,200,100,0.5)",
        margin: "0 auto",
        fontFamily: "Modern",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          margin: "1rem 1rem 0rem 1rem",
          borderBottom: "solid 2px #222",
          paddingBottom: "0.5rem",
        }}
      >
        <motion.div
          whileHover={{ backgroundColor: "#FFF0A0" }}
          style={selectedTab === "1" ? gameplayButtonActive : gameplayButtons}
          onClick={() => setSelectedTab("1")}
        >
          Game Summary
        </motion.div>
        <motion.div
          whileHover={{ backgroundColor: "#FFF0A0" }}
          style={selectedTab === "2" ? gameplayButtonActive : gameplayButtons}
          onClick={() => setSelectedTab("2")}
        >
          Specs & Stats
        </motion.div>
        <motion.div
          whileHover={{ backgroundColor: "#FFF0A0" }}
          style={selectedTab === "3" ? gameplayButtonActive : gameplayButtons}
          onClick={() => setSelectedTab("3")}
        >
          How to Play
        </motion.div>
        <motion.div
          whileHover={{ backgroundColor: "#FFF0A0" }}
          style={selectedTab === "4" ? gameplayButtonActive : gameplayButtons}
          onClick={() => setSelectedTab("4")}
        >
          Current Game Status
        </motion.div>
      </div>
      <div
        style={{ width: "100%", height: "100%", overflow: "auto" }}
        ref={scrollRef}
      >
        {selectedTab === "1" && <AboutGame setSelectedTab={setSelectedTab} />}
        {selectedTab === "2" && <SpecsAndStats />}
        {selectedTab === "3" && <HowToPlay />}
        {selectedTab === "4" && <CurrentGameStatus />}
      </div>
    </div>
  );
};

export default Gameplay;
