import { useEffect, useState } from "react";
import Nav from "./sections/Nav";
import Header from "./sections/Header";
import Gameplay from "./sections/Gameplay";
import Subscribe from "./sections/Subscribe";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import Multimedia from "./sections/Multimedia";
import RandomCardTitles from "./components/RandomCardTitles";
import QuickLinks from "./sections/QuickLinks";

const Page = ({ randomCardTitles }) => {
  useEffect(() => {
    document.title =
      "Society | The Epic Battle of Investments, Politics & Personal Greatness";

    const metaTag = document.createElement("meta");
    metaTag.setAttribute("property", "og:image");
    metaTag.setAttribute("content", "icon.ico");
    document.head.appendChild(metaTag);

    return () => {
      //cleanup on unmount
      //document.head.removeChild(metaTag);
    };
  }, []);

  const [scrollY, setScrollY] = useState(0);

  const [selectedTab, setSelectedTab] = useState("1");

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        fontFamily: "Sleek",
        color: "white",
        background: "#222",
      }}
    >
      <Nav scrollY={scrollY} setSelectedTab={setSelectedTab} />
      <Header scrollY={scrollY} />
      <div style={{ position: "relative", zIndex: "30" }}>
        <Gameplay
          scrollY={scrollY}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <QuickLinks />
        <Multimedia />
        <Subscribe/>
        <Contact />
        <Footer />
      </div>
      {randomCardTitles}
    </div>
  );
};

export default Page;
