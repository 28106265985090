import ImageViewer from "../components/ImageViewer.js";

const HowToPlay = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <h2 style={{ textAlign: "center", textDecoration: "underline" }}>
        How to Play SOCIETY
      </h2>
      <h3
        style={{
          color: "black",
          fontSize: "150%",
          marginTop: "4rem",
          fontFamily: "Sleek",
          fontVariant: "all-small-caps",
        }}
      >
        Setup, The 1st Turn, & the Personal Life Deck
      </h3>
      <p>
        Players begin by sitting around the board each with a player piece (each
        shaped like a different animal), a pile of their "Private Investment"
        tokens (each with a picture of the player's animal), a pile of cash -
        the amount of which is determined by the number of players and the
        ruleset being used.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
      <ImageViewer filename={"box_front.jpg"} alt="The Front of the Box for the current version. All of the art will be improved for the final retail version." />
        <ImageViewer filename={"board.jpg"} alt="The Society Game Board" />
      </div>
      <p>
        Each player begins with one basic movement, and so the first turn for
        every player is a movement from the "National Holiday" starting space
        clockwise to the adjacent "Draw a Personal Life Card" space. Players
        will then draw a card from the "Personal Life" deck. The Personal Life
        Deck contains four different types of cards including Career, Property,
        Education and Skill cards.
      </p>
      <ImageViewer
        filename={"personal_life_fan.jpg"}
        alt="Cards from the Personal Life Deck"
      />
      <p>
        Property cards can be purchased by the player who draws the card for a
        price determined by the corresponding number of investments in the
        Economic Sectors on the gameboard which affect that card.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <ImageViewer
          filename={"property_with_sectors.jpg"}
          alt="A Property Card next to Affected Sectors on the gameboard."
        />
        <ImageViewer
          filename={"property_fan.jpg"}
          alt="Some examples of Property Cards."
        />
      </div>
      <p>
        Property Cards can be sold later in the game for a profit, but their
        primary use is to raise a player's "movement" count - the number of
        spaces that player can move each turn. Each Property's movement count is
        determined by the number of investments of the Economic Sector listed on
        the card.
      </p>
      <p>
        While players may choose to purchase several properties for a variety of
        reasons, only one Property Card can be a player's "Primary Residence",
        and so only one Property can affect a player's movement count or have
        it's special abilities used. Property Cards can also be bought or sold
        on the "Moving Day" space.
      </p>
      <p>
        Career Cards are used to determine a player's salary. If a player draws
        a Career card, they may only keep it if they possess the Required
        Education for that career - though many careers require no formal
        education. The Salary for a given career is determined by the number of
        investment pieces in the card's corrosponding Economic Sectors on the
        gameboard.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <ImageViewer
          filename={"career_with_sectors.jpg"}
          alt="A Career Card next to it's corrosponding Economic Sectors."
        />
        <ImageViewer
          filename={"career_fan.jpg"}
          alt="Some examples of Career Cards."
        />
      </div>
      <p>
        Education Cards have two purposes: to allow a player to get a better
        paying job, and to add to a player's movement count. The more
        prestigeous the school, the higher the added movement count. Education
        cards have a "Tuition Price" which must be paid to obtain the education,
        UNLESS there is an adequate level of "Public Investments" in the
        Education Economic Sector on the gameboard. (More on Public Investments
        later.)
      </p>
      <ImageViewer
        filename={"education_fan.jpg"}
        alt="Some examples of Education Cards."
      />
      <p>
        Skill cards are relatively rare finds in the Personal Life Deck. Each
        one gives the player who draws it a special ability which may or may not
        help them reach their victories.
      </p>
      <ImageViewer
        filename={"skill_fan.jpg"}
        alt="Some examples of Skill Cards."
      />
      <h3
        style={{
          color: "black",
          fontSize: "150%",
          marginTop: "4rem",
          fontFamily: "Sleek",
          fontVariant: "all-small-caps",
        }}
      >
        The 2nd Turn, Movements, Private Investments & the Society Deck
      </h3>
      <p>
        On their second turn, players may still only have their one basic
        movement, or they may have aquired an Education or Property card which
        gives them additional movements. This early in the game, it is unlikely
        any player would have more than four additional moves (making their
        movement count 5 in total). If a player has an Education card which
        gives them +2 Movements, then their movement count is now 3, and they
        would have the choice of moving from the "Draw a Personal Life Card"
        space they landed on during the first turn, and moving to any of the
        next three spaces.
      </p>
      <ImageViewer
        filename={"first_few_spaces.jpg"}
        alt="The first side of the gameboard."
      />
      <p>
        If a player chooses to land on the "Buy or Sell ONE Private Investment"
        space (or only has their one basic movement, and thus has no other
        choice) then that player may choose to place one of their Private
        Investment tokens on the lowest available Investment Level space in any
        Economic Sector, and pay the corresponding amount to make the
        Investment.
      </p>
      <ImageViewer
        filename={"lowest_available_level2.jpg"}
        alt="In this picture, the lowest available level for the Food Production Sector is 2, for the Manufacturing Sector is 1, and for Infrastructure is 4. New investments must always be placed in the lowest available Investment Level space, and cost the dollar amount denoted below the investment space."
      />
      <p>
        As more players purchase investments in that Economic Sector, not only
        does the "Investment Level" increase, but so too does the value of each
        investment token in that Sector. When a player chooses to sell one of
        their Private Investments, they receive the amount determined by the
        highest current Investment Level in that Sector, and once their token is
        removed all other investment tokens in the Sector are moved down one,
        reducing the Sector's Investment Level as well as the value of the
        remaining Private Investment tokens.
      </p>
      <p>
        Private Investments aren't just a good way to make money by buying low
        and selling high, nor are they simply a mechanism to grow the economy
        and affect prices or values. Many cards drawn from the Society deck will
        result in players being paid for their investments as well. (More on
        this below.)
      </p>
      <p>
        Drawing from the Society Deck can bring either fortune or difficulty.
        The main reason players draw from the Society Deck is to find "Victory
        Goal" cards, which are the keys to winning the game. However, Victory
        Goal cards are only one of the four types of cards found in the Society
        deck, the others being: Personal Achievements, Personal Expenses, and
        Public Projects. Aside from Victory Cards (which are either placed in
        front of the player who drew the card, or discard), cards in the Society
        deck must be played immediately after being drawn, and are then put in
        the discard pile.
      </p>
      <ImageViewer
        filename={"society_fan.jpg"}
        alt="The Society Deck with examples of the four types of cards."
      />
      <p>
        When a player draws a Victory Goal card, they may place it in front of
        them. Once that Victory's unique conditions have been met, the player
        turns the card over to indicate they've acheived a victory. The standard
        rules require a player to acheive two separate victories (not
        simultaneously) to win the game, though different rulesets & game
        lengths might see anywhere between one and five victories being required
        to win.
      </p>
      <ImageViewer
        filename={"victory_goal_fan.jpg"}
        alt="Some examples of Victory Goal cards."
      />
      <p>
        If a player draws a Personal Acheivement card, they are rewarded with an
        amount of cash determined by the Investment Level of the corrosponding
        Economic Sector on the gameboard.
      </p>
      <ImageViewer
        filename={"personal_achievement_fan.jpg"}
        alt="Some examples of Personal Acheviement cards."
      />
      <p>
        If a player draws a Personal Expense card, it's usually costly for them,
        and profitable for other players. Personal Expense Cards come with an
        "Investment Payout" amount, which is determined by the Investment Level
        of one of the Economic Sectors. The player who drew the card must then
        pay the Investment Payout to EACH other player for each Private
        Investment token they have in the listed "Affected Sectors". As you
        might imagine, while the cost of a Personal Expense is relatively low in
        the early-mid game, in the late game it can often cause a player to take
        on serious debt, or potentially also make another player very wealthy if
        they happen to be invested in the right Economic Sectors.
      </p>
      <ImageViewer
        filename={"personal_expense_fan.jpg"}
        alt="Some examples of Personal Expense cards."
      />
      <p>
        Public Project Cards are similar to Personal Expense Cards except that
        the Investment Payouts are paid from Public Funds (more on Public Funds
        later) rather than from the player who drew the card.
      </p>
      <ImageViewer
        filename={"public_project_fan.jpg"}
        alt="Some examples of Public Project cards."
      />
      <p>
        Some cards will have an additional effect that occurs when the card is
        drawn.
      </p>
      <ImageViewer
        filename={"special_effects.jpg"}
        alt="Some examples of Public Project and Personal Expense cards with special effects."
      />
      <h3
        style={{
          color: "black",
          fontSize: "150%",
          marginTop: "4rem",
          fontFamily: "Sleek",
          fontVariant: "all-small-caps",
        }}
      >
        The 3rd Turn & Onward, The Salary Space, Taxes, Public Funds & The
        Calendar Seasons
      </h3>
      <p>
        By the third turn and onward players will each continue the pattern of
        moving forward to a space of their choosing, determined by the total
        movements between their Primary Property and their Primary Education, in
        addition to their 1 basic movement they each started with. The early
        part of the game will be mainly focused on players finding careers,
        purchasing properties or getting educations to increase their movements,
        and making investments in the various economic sectors while it's
        inexpensive to do so.
      </p>
      <p>
        Players get paid when they land on the Salary Space - assuming they have
        a Career when they get there. The total amount they get paid will be
        determined not only by the Income listed on their Career Card, but also
        on the Tax Rate.
      </p>
      <ImageViewer
        filename={"salary_space.jpg"}
        alt="The Collect Your Salary Space."
      />
      <p>
        Players are taxed different amounts depending on which Tax Bracket they
        fall into. The tax amount and where the brackets are divided are
        determined by the placement of Tax Tokens on the gameboard. More on that
        in a moment.
      </p>
      <ImageViewer
        filename={"tax_brackets.jpg"}
        alt="The Tax Brackets area with Tax Tokens on it. The taxes paid in a given bracket is equal to the cumulative value of any tokens in that bracket or lower. Here, the Base rate is $25 for any income, anyone making >$150 pays $50 ($25 extra), and anyone making >$250 pays $100 ($50 on top of the previous two brackets) in taxes."
      />
      <p>
        A player will only keep the portion of their Salary that remains after
        they've paid taxes, and all of the money collected in Taxes gets placed
        in the "Public Funds" area of the gameboard.
      </p>
      <ImageViewer
        filename={"public_funds.jpg"}
        alt="The Public Funds space on the gameboard."
      />
      <p>
        Public Funds is used to pay for Public Projects (drawn from the Society
        Deck), and to pay for any Public Investment tokens on the board at the
        End of the Calendar Year.
      </p>
      <ImageViewer
        filename={"publicly_funded_sectors.jpg"}
        alt="Public Investment Tokens on the Society gameboard."
      />
      <p>
        The Calendar peice gets advanced every time a player lands on the
        "Collect Your Salary" space. The current season will affect many cards
        both in the Personal Life and Society Decks.
      </p>
      <ImageViewer filename={"calendar.jpg"} alt="The Calendar Seasons" />
      <h3
        style={{
          color: "black",
          fontSize: "150%",
          marginTop: "4rem",
          fontFamily: "Sleek",
          fontVariant: "all-small-caps",
        }}
      >
        Public Investments, Politics, the Remaining Spaces
      </h3>
      <p>
        Throughout the game, players will have to get political if they'd like
        to change taxes or affect government spending. By landing on a
        "Legislative Motion" space, players may raise a vote to make some kind
        of change. Alternatively, some spaces on the board allow players to pay
        a fee in order to make a change of their liking without the support of
        other players.
      </p>
      <ImageViewer
        filename={"legislative_motion.jpg"}
        alt="The Legislative Motion space."
      />
      <p>
        Public Investment pieces get added and removed to the various Economic
        Sectors throughout the game, and there are a number of reasons players
        may prefer or not to have public investment in any given Economic
        Sector. When a Personal Expense or a Public Project card must pay the
        corrosponding Affected Sectors, they need not pay for the Public
        Investment tokens - thus an Economic Sector that is entirely funded by
        Public Investments eliminates any costs associated with that Sector to
        individual players.
      </p>
      <ImageViewer
        filename={"publicly_funded_education.jpg"}
        alt="A Publiclly Funded Education Sector with THREE Public Investments."
      />
      <p>
        The politics of the game get interesting when multiple players have
        similar interests, or when one player is particularly close to acheiving
        their victory. Players may or may not opt to negotiate deals with one
        another in order to find mutual benefit.
      </p>
      <p>
        The remaining spaces on the board offer specialized opportunities to do
        more of the things we've already discussed here. Moving Day & Back to
        School allow players to flip through the deck until they draw a
        Property/Education card. There are some spaces which allow player to Buy
        or Sell up to three Private Investments in a single turn. Certain spaces
        will allow a player to draw two Society Cards, and play either one or
        both of them.
      </p>
      <p></p>
      <p>
        If you've taken the time to read all of this, please know that we
        greatly appreciate it! Hopefully you're excited to play our game, and we
        hope to hear from you!
      </p>
    </div>
  );
};

export default HowToPlay;
