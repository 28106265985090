import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import EmailRequest from '../components/EmailRequest'

const QuickLinks = () => {

    const quickLinkStyle = {
        width: '24vw',
        height: '24vw',
        fontSize: '4vw',
        border: 'solid 1rem black',
        borderRadius: '15vw',
        background: '#F8DE5E',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '3vw',
        wordwrap: 'wrap',
        textAlign: 'center',
        fontWeight: 'bold',
        fontFamily: 'Futuristic',

    }
    
    const linkAnimate = {
    }

    const linkTransition = {

    }

    return (
        <div style={{ overflow: 'hidden', width: '100%', padding: '4rem 0rem', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0rem' }}>
            <EmailRequest type='playRequest' ><motion.div initial={{ opacity: 0.7 }} animate={linkAnimate} transition={linkTransition} whileHover={{ opacity: 1 }} whileTap={{ opacity: 1, scale: 1.1 }} style={{...quickLinkStyle,...{ backgroundImage: 'none'}}}>Request to Play a Trial</motion.div></EmailRequest>
            <EmailRequest type='contact' ><motion.div initial={{ opacity: 0.7 }} animate={linkAnimate} transition={linkTransition} whileHover={{ opacity: 1 }} whileTap={{ opacity: 1, scale: 1.1 }} style={quickLinkStyle}>Send Us a Message</motion.div></EmailRequest>
            <EmailRequest type='crowdfunding' ><motion.div initial={{ opacity: 0.7 }} animate={linkAnimate} transition={linkTransition} whileHover={{ opacity: 1 }} whileTap={{ opacity: 1, scale: 1.1 }} style={quickLinkStyle}>Crowdfund Our Game</motion.div></EmailRequest>
        </div>
    )
}

export default QuickLinks;