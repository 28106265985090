import Page from "./Page";
import { Analytics } from "@vercel/analytics/react";
import firebase from "./firebase";

import RandomCardTitles from "./components/RandomCardTitles";

function App() {

  // component is passed into page as a prop so that it's not re-rendered when the scrollY is changed
  return (
    <>
      <Page randomCardTitles={<RandomCardTitles/>} />
      <Analytics />
    </>
  );
}

export default App;
