import EmailRequest from "../components/EmailRequest";

const AboutGame = ({ scrollY, setSelectedTab }) => {
  return (
    <div
      style={{
        width: "96%",
        padding: "0.5rem 1rem",
        backgroundColor: "#333",
        backgroundImage: 'url("textures/bright-squares.png")',
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: "0.05rem 1rem",
          maxWidth: "600px",
          margin: "0 auto",
          background: "white",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            textDecoration: "underline",
            fontFamily: "Futuristic",
          }}
        >
          Game Summary
        </h2>
        <p
          style={{
            textAlign: "center",
            margin: "0.5rem 2rem",
            color: "#FC0",
            border: "solid 4px #FC0",
            borderRadius: "1rem",
            padding: "0.5rem",
          }}
        >
          Thanks for your interest in our game! Please check out the <span style={{ cursor: 'pointer', color: '#0BF' }} onClick={() => setSelectedTab('3')}>"How to
          Play"</span> button above to see more pictures & detailed information.
        </p>
        <p>
          In SOCIETY, between two and six players must both compete and
          cooperate with one another to acheive their own personal victories.
          The nature of those victories will be different for every player,
          depending on the cards they draw and choose to keep throughout the
          game.
        </p>
        <p>
          Part investment sim, part RPG, and part strategy game - Society has
          something for everyone, and there are countless different ways to
          reach your victories each game.
        </p>
        <p>
          Each turn is about choices. Each choice will affect your own personal
          strategy, as well as the way other players approach you. Factors such
          as your education or where you live can affect how many choices you
          have each turn, while your career and investment choices will
          determine your cash flow.
        </p>
        <p>
          Ultimately, every element of the game is determined by the investments
          which players (as well as the government) make in each of the twelve
          Economic Sectors. Each career's salary, each property's value, and the
          effects of every event in the game are determined by the investments
          in their related Economic Sectors.
        </p>
        <p>
          Of course, aside from affecting the economy, investments can be used
          to make money too. By strategically investing in Economic Sectors that
          not only affect you but other players too, you can buy low and sell
          high to make a profit at their expense, or use your investments
          strategically to crash a market right when it hurts a rival the most.
        </p>
        <p>
          In addition to players acting as individuals with separate interests,
          player must also work together to ensure that their government remains
          adequately funded, and negotiate with one another in deciding how
          public funds will be spent. Failure to properly manage government
          finances can be catastrophic for everyone, though a properly motivated
          alliance can use political dominance to mould government as they see
          fit - for better or worse.
        </p>
        <p>
          Each game will unfold in an entirely different way, as your approach
          to every situation will depend on what each other player is doing. No
          two cards are the same in either of the 90 card decks, and changing
          factors such as the seasons, the tax rates, and the manner in which
          public funds are spent will ensure that no two games are ever the
          same.
        </p>
        <p>
          And if you're looking for an experience that's slightly less
          capitalistic, SOCIETY can also be played in an entirely different
          cooperative mode, in which players work together in a
          difficult-yet-possible challenge to develop a self-sustaining, fully
          socialist society in which 100% of income is taxed, the economy is
          strong, and everyone's needs are fully met by the state.
        </p>
        <p>
          If this sounds like a game that would interest you, please <EmailRequest type='updates'>sign up to receive updates</EmailRequest> about the game's development or when/where it will be available for sale, 
          or <EmailRequest type='playRequest'>request to play the game</EmailRequest> while it's in beta testing yourself.
        </p>
      </div>
    </div>
  );
};

export default AboutGame;
